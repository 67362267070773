import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import logo from "../images/logo.svg"

function Header({}) {

  return (
    <div className="container mx-auto max-w-7xl px-4 flex items-center justify-between pt-6 md:pt-10 pb-16 md:pb-24 z-20 relative">
      <Link to="/">
        <img src={logo} className="w-8 h-8 md:w-10 md:h-10" />
      </Link>
      <div className="flex items-center space-x-3 md:space-x-4">
      <Link to="/contact" className="text-white font-medium sm:text-base md:text-lg bg-zinc-900 rounded-full px-4 sm:px-6 py-2 sm:py-3 flex items-center space-x-2 sm:space-x-3 hover:bg-black transition-colors group">
        <span className="relative z-10">Work With Me</span>
            <svg className="fill-current w-5 h-5 transform transition-transform group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="30" height="30"
viewBox="0 0 30 30"><path d="M 22.990234 8.9902344 A 1.0001 1.0001 0 0 0 22.292969 10.707031 L 25.585938 14 L 2 14 A 1.0001 1.0001 0 1 0 2 16 L 25.585938 16 L 22.292969 19.292969 A 1.0001 1.0001 0 1 0 23.707031 20.707031 L 28.619141 15.794922 A 1.0001 1.0001 0 0 0 28.623047 14.207031 A 1.0001 1.0001 0 0 0 28.617188 14.203125 L 23.707031 9.2929688 A 1.0001 1.0001 0 0 0 22.990234 8.9902344 z"></path></svg>
      
      </Link>

      </div>
      
    </div>
  )
}


export default Header
